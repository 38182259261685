import { render, staticRenderFns } from "./PortfolioGallery.vue?vue&type=template&id=0096b0a5&"
import script from "./PortfolioGallery.vue?vue&type=script&lang=js&"
export * from "./PortfolioGallery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports